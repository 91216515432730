<template>
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.2165 19.6343C10.1498 18.9343 10.8498 17.8843 10.8498 16.601C10.7332 14.501 9.09984 12.751 6.99984 12.751C4.89984 12.751 3.2665 14.501 3.2665 16.4843C3.2665 17.7676 3.84984 18.8176 4.89984 19.5176C2.68317 20.451 1.1665 22.551 1.1665 25.001C1.1665 25.351 1.39984 25.5843 1.74984 25.5843C2.09984 25.5843 2.33317 25.351 2.33317 25.001C2.33317 22.4343 4.43317 20.3343 6.99984 20.3343C9.5665 20.3343 11.6665 22.4343 11.6665 25.001C11.6665 25.351 11.8998 25.5843 12.2498 25.5843C12.5998 25.5843 12.8332 25.351 12.8332 25.001C12.8332 22.551 11.3165 20.451 9.2165 19.6343ZM6.99984 19.1676C5.59984 19.1676 4.43317 18.001 4.43317 16.601C4.43317 15.201 5.59984 13.9176 6.99984 13.9176C8.39984 13.9176 9.5665 15.0843 9.5665 16.4843C9.5665 18.001 8.39984 19.1676 6.99984 19.1676ZM23.2165 19.6343C24.1498 18.9343 24.8498 17.8843 24.8498 16.601C24.8498 14.501 23.0998 12.8676 21.1165 12.8676C19.1332 12.8676 17.3832 14.6176 17.3832 16.601C17.3832 17.8843 17.9665 18.9343 19.0165 19.6343C16.9165 20.451 15.3998 22.551 15.3998 25.001C15.3998 25.351 15.6332 25.5843 15.9832 25.5843C16.3332 25.5843 16.5665 25.351 16.5665 25.001C16.5665 22.4343 18.6665 20.3343 21.2332 20.3343C23.7998 20.3343 25.8998 22.4343 25.8998 25.001C25.8998 25.351 26.1332 25.5843 26.4832 25.5843C26.8332 25.5843 27.0665 25.351 27.0665 25.001C26.8332 22.551 25.3165 20.451 23.2165 19.6343ZM20.9998 19.1676C19.5998 19.1676 18.4332 18.001 18.4332 16.601C18.4332 15.201 19.5998 14.0343 20.9998 14.0343C22.3998 14.0343 23.5665 15.201 23.5665 16.601C23.5665 18.001 22.3998 19.1676 20.9998 19.1676Z"
      fill="currentColor"
    />
    <path
      d="M10.6169 5.92591L13.5336 3.00924C13.6502 3.00924 13.6502 2.89258 13.7669 2.89258L14.2336 2.89258C14.3502 2.89258 14.4669 3.00924 14.4669 3.00924L17.2669 5.92591C17.5002 6.15925 17.5002 6.50925 17.2669 6.74258C17.0336 6.97591 16.6836 6.97591 16.4502 6.74258L14.5836 4.87591L14.5836 13.7426L16.4502 11.8759C16.6836 11.6426 17.0336 11.6426 17.2669 11.8759C17.5002 12.1092 17.5002 12.4592 17.2669 12.6926L14.3502 15.6092C14.1169 15.8426 13.7669 15.8426 13.5336 15.6092L10.6169 12.6926C10.6169 12.5759 10.5002 12.3426 10.5002 12.2259C10.5002 11.8759 10.7336 11.6426 11.0836 11.6426C11.2002 11.6426 11.4336 11.7592 11.5502 11.8759L13.4169 13.7426L13.4169 4.87591L11.4336 6.85924C11.2002 7.09258 10.8502 7.09258 10.6169 6.85924C10.6169 6.74258 10.5002 6.50924 10.5002 6.39258C10.5002 6.15924 10.5002 6.04258 10.6169 5.92591Z"
      fill="currentColor"
    />
  </svg>
</template>
